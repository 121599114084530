<template lang="html">
	
	<div class="h-full flex flex-col overflow-hidden">
		<div class="flex-none flex gap-4 px-8 pt-8 pb-4">
			<!-- <div class="">
				transaction
				<div v-if="userPassed">
					<pre>{{userPassed}}</pre>
				</div>
				<div v-if="department">
					<pre>{{department}}</pre>
				</div>
			</div> -->
			
			<a 
				class="flex-1 text-center rounded-lg px-3 py-1 border border-beige-light" 
				:class="activePreset == 'week' ? 'bg-gray-darker text-white' : 'bg-beige border border-beige-dark'"
				@click="onSetPreset('week')">
				
				Uke
			</a>
			<a 
				class="flex-1 text-center rounded-lg px-3 py-1 border border-beige-light" 
				:class="activePreset == 'month' ? 'bg-gray-darker text-white' : 'bg-beige border border-beige-dark'"
				@click="onSetPreset('month')">
				
				Måned
			</a>
			<a 
				class="flex-1 text-center rounded-lg px-3 py-1 border border-beige-light" 
				:class="activePreset == 'year' ? 'bg-gray-darker text-white' : 'bg-beige border border-beige-dark'"
				@click="onSetPreset('year')">
				
				År
			</a>
			<a 
				class="flex-1 text-center rounded-lg px-3 py-1 border border-beige-light" 
				:class="activePreset == 'custom' ? 'bg-gray-darker text-white' : 'bg-beige border border-beige-dark'"
				@click="onSetPreset('custom')">
				
				Egendefinert
			</a>
		</div>
		
		
		<div class="flex-grow flex flex-col overflow-auto px-8 pb-8">
			<div 
				v-if="activePreset == 'custom'"
				class="pb-4">
				
				<DatePicker 
					range 
					v-model="dateRange" 
					:clearable="false"
					:open="true"
					:inline="true"
					class="cursor-pointer" 
					:value-type="'timestamp'"
					:lang="{formatLocale: {firstDayOfWeek: 1}}"
				/>
			</div>
			
			<!-- <div class="bg-blue-400">
				Filter: oppgaver og/eller produkter
			</div> -->
			
			<!-- v-if="activePreset != 'custom'" -->
			<div 
				class="flex-none flex pt-4 pb-8 text-lg font-bold leading-tight ">
				
				<div 
					class="text-left"
					v-if="from">
					
					<span class="capitalize">{{ from | moment('ddd') }}</span> <span>{{ from | moment('ll') }}</span>
					kl. <span>{{from | moment('LT')}}</span>
				</div>
				<div class="flex-none w-12 flex justify-center items-start mt-1">
					<svg class="w-4" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M21.9521 7.57964L12.9521 0.579636V5.57964H0.952148V9.57964H12.9521V14.5796L21.9521 7.57964Z" fill="#444"/>
					</svg>
				</div>
				<div 
					class="text-right"
					v-if="to">
					
					<span class="capitalize">{{ to | moment('ddd') }}</span> <span>{{ to | moment('ll') }}</span>
					kl. <span>{{to | moment('LT')}}</span>
				</div>
			</div>
			
			<div 
				v-if="loading == 'fetching'"
				class="h-full flex items-center justify-center">
				
				<div class="spinner"></div>
			</div>
			<div 
				v-else-if="transactions && transactions.length"
				class="grid grid-cols-1 gap-6">
				
				<div
					v-for="t in transactions"
					:key="'departmentTransaction_'+t.transactionId">
					
					<TransactionCard 
						:transaction="t"
						:type="userPassed ? 'department' : 'user'"
						:users="users"
						:departments="departments">
						
						<i 
							class="far fa-edit w-8 text-lg text-center text-beige-darkest" 
							@click="transactionEdit = t"
						/>
						<i 
							class="far fa-trash-alt w-8 text-lg text-center text-beige-darkest" 
							@click="onDeleteTransaction(t)"
						/>
					</TransactionCard>
					
					<div 
						v-if="kudos"
						class="flex py-4 justify-end">
						
						<div class="flex-grow">
							<!-- Kudos -->
						</div>
						
						<div class="flex-none space-x-4 flex">
							<div 
								class="flex cursor-default"
								v-for="symbol in ['heart', 'thumbsUp']"
								:key="'symbol_'+t.transactionId+'_'+symbol">
								
								<div 
									class="rounded-full flex items-center justify-center space-x-1 px-2 h-8"
									:class="kudos.filter( kudo => kudo.transactionId == t.transactionId && kudo.symbol == symbol && kudo?.from.username == signedInUser.username).length ? `border border-green bg-green text-white` : 'border border-beige-dark'"
									@click="onCreateKudo(t, symbol)">
									
									<i 
										class="fas w-6 text-center fa" 
										:class="symbol == 'thumbsUp' ? 'fa-thumbs-up' : 'fa-heart'"
									/>
									
									<div
										v-if="kudos.filter( kudo => kudo.transactionId == t.transactionId && kudo.symbol == symbol).length"
										class="text-center px-1 w-8">
										
										{{ kudos.filter( kudo => kudo.transactionId == t.transactionId && kudo.symbol == symbol).length }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div 
				v-if="transactions && transactions.length == 0"
				class="h-full flex items-center justify-center">
				
				Fant ingen transaksjoner i perioden
			</div>
			
			<!-- kudos:
			<div v-if="kudos?.length">
				<pre>{{ kudos }}</pre>
			</div> -->
		</div>
		
		<div 
			v-if="transactions && transactions.length"
			class="flex-none flex p-6 space-x-4">
			
			<select 
				class="flex-grow"
				v-model="exportFormat">
				
				<option value="excel">Excel regneark</option>
				<!-- <option value="csv">CSV (Comma separated values)</option> -->
			</select>
			
			<button 
				type="submit"
				@click="onTransactionExport( transactions )"
				class="button submit">
				
				Last ned
			</button>
		</div>
		
		<Modal 
			v-if="transactionEdit"
			@close="transactionEdit = null"
			insideClasses="bg-white">
			
			<header slot="header">
				Rediger transaksjon
			</header>
			
			<div class="overflow-auto">
				<TransactionEdit 
					ref="TransactionEditRef"
					:transaction="transactionEdit"
				/>
			</div>
			
            <footer slot="footer">
                <div class="space-x-4 text-right">
                    <a 
                        class="button" 
                        @click="transactionEdit = null">
                        
                        Avbryt
                    </a>
                    
                    <button 
                        type="submit"
                        @click.prevent="onUpdateTransaction()" 
                        :disabled="loading == 'creating'"
                        class="button submit"
                        :class="{'loading' : loading == 'creating'}">
                        
                        Lagre endringer
                    </button>
                </div>
            </footer>
		</Modal>
		
	</div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import ExportTransactionsMixins from '@/mixins/ExportTransactionsMixins';

export default {
	mixins: [
		ExportTransactionsMixins,
	],
	
	components: {
		DatePicker,
		'TransactionCard': () => import('@/components/transactions/TransactionCard.vue'),
		'TransactionEdit': () => import('@/components/transactions/TransactionEdit.vue'),
	},
	
	props: { 
		department: {
			type: Object,
			required: false,
		},
		
		userPassed: {
			type: Object,
			required: false,
		},
	},
	
	computed:{
		users(){
			return this.$store.getters.getUsers;
		},
		
		departments(){
			return this.$store.getters.getDepartments;
		},
		
		kudos(){
			return this.$store.getters.getKudos;
		},
	},
	
	data(){
		return {
			transactions: null,
			// kudos: null,
			from: null,
			to: null,
			activePreset: 'week',
			loading: null,
			dateRange: null,
			exportFormat: 'excel',
			transactionEdit: null,
			// excel: {
			// 	data: null,
			// 	columns: null,
			// 	options: {
			// 		fileName: 'Upsell Export.xlsx',
			// 		fontFamily: 'Arial',
			// 		fontSize: 10,
			// 	},
			// },
		}
	},
	
	watch: {
		dateRange: function (newValue, oldValue) {
			this.from = newValue[0];
			let to = this.$moment( newValue[1] ).endOf('day').valueOf();
			this.to = to;
			
			this.onFetchTransactions();
		},
	},
	
	methods: {
		async onSetPreset(preset){
			switch ( preset ) {
				case 'week':
					// this.from = this.$moment.utc().startOf('day').subtract(1, 'W').valueOf(); 
					// this.to = this.$moment.utc().endOf('day').valueOf();
					this.from = this.$moment().startOf('week');
					this.to = this.$moment().endOf('week');
					break
					
				case 'month':
					// this.from = this.$moment.utc().startOf('day').subtract(1, 'M').valueOf(); 
					// this.to = this.$moment.utc().endOf('day').valueOf();
					this.from = this.$moment().startOf('month');
					this.to = this.$moment().endOf('month');
					break
					
				case 'year':
					// this.from = this.$moment.utc().startOf('day').subtract(1, 'Y').valueOf(); 
					// this.to = this.$moment.utc().endOf('day').valueOf();
					this.from = this.$moment().startOf('year');
					this.to = this.$moment().endOf('year');
					break
					
				case 'custom':
					// this.from = this.$moment().startOf('week');
					// this.to = this.$moment().endOf('week');
					let from = this.$moment().startOf('week').valueOf();
					let to = this.$moment().endOf('week').valueOf();
					
					this.dateRange = [
						from,
						to,
					];
					
					console.log('this.dateRange', this.dateRange);
					break
			}
			
			this.activePreset = preset;
			
			if (preset != 'custom') {
				this.from = this.from.valueOf();
				this.to = this.to.valueOf();
				
		  		this.onFetchTransactions();
			}
			
			console.log('this.from', this.from);
			console.log('this.to', this.to);
		},
		
		async onFetchTransactions(){
			try {
				this.loading = 'fetching';
				
				let params = {
					from: this.from,
					to: this.to,
				};
				
				if ( this.department ) {
					params.departmentId = this.department.departmentId;
				}
				else if ( this.userPassed ) {
					params.username = this.userPassed.username;
				}
				
				this.transactions = await this.$store.dispatch('listTransactions', params );
				await this.$store.dispatch('listKudos', params );
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke hente transaksjoner'
				});
				
				console.error(e);
			} 
			finally {
				this.loading = null;
			}
		},
		
		async onDeleteTransaction( transaction ){
			try {
				if (! confirm('Er du sikker på at du vil slette transaksjonen?')) return; 
				
				this.loading = 'deleting';
				const response = await this.$store.dispatch('deleteTransaction', transaction);
				this.transactions = this.transactions.filter( t => t != transaction);
				this.$notify({ type: 'success', text: 'Transaksjonen ble slettet' });
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke slette transaksjonen'
				});
			
				console.error(e);
			} 
			finally {
				this.loading = null;
			}
		},
		
		async onUpdateTransaction(){
			try {
				this.loading = 'updating';
				const transactionEdit = this.$refs.TransactionEditRef.transactionEdit;
				const response = await this.$store.dispatch('updateTransaction', transactionEdit);
				
				this.transactions = this.transactions.map( t => {
					if ( t.transactionId == transactionEdit.transactionId) {
						return response;
					}
					return t
				});
				
				this.transactionEdit = null;
				this.$notify({ type: 'success', text: 'Transaksjonen ble endret' });
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke endre transaksjonen'
				});
			
				console.error(e);
			} 
			finally {
				this.loading = null;
			}
		},
		
		async onCreateKudo( transaction, symbol ){
			try {
				if (transaction.username == this.signedInUser.username) {
					throw Error('Du kan ikke gi deg selv kudos.');
				}
				
				this.loading = 'creatingKudo';
				
				const kudo = {
					transactionId: transaction.transactionId,
					departmentId: transaction.departmentId,
					username: transaction.username, // to username
					from: {
						username: this.signedInUser.username,
					},
					symbol,
				};
				
				const kudosWithSameSymbolFromThisUsername = this.kudos
					.filter( kudo => kudo.transactionId == transaction.transactionId)
					.filter( kudo => kudo.symbol == symbol)
					.filter( kudo => kudo.from.username == this.signedInUser.username);
				
				console.log( {kudosWithSameSymbolFromThisUsername} );
				
				if (kudosWithSameSymbolFromThisUsername.length) {
					throw Error('Denne har du gitt allerede');
				}
				
				const response = await this.$store.dispatch('createKudo', kudo);
				// this.transactions = this.transactions.filter( t => t != transaction);
				this.$notify({ type: 'success', text: 'Kudo ble gitt' });
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke gi kudos'
				});
			
				console.error(e);
			} 
			finally {
				this.loading = null;
			}
		},
	},
	
	async mounted(){
		this.onSetPreset( this.activePreset );  
		
		if ( !this.$store.getters.getUsers ) {
			this.loading = 'fetching';
			await this.$store.dispatch('listUsers');
			this.loading = null;
		}
		
		if ( !this.$store.getters.getDepartments ) {
			this.loading = 'fetching';
			await this.$store.dispatch('listDepartments');
			this.loading = null;
		}
	},
}
</script>