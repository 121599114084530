import writeXlsxFile from 'write-excel-file';

export default {
	
	data(){
		return {
			excel: {
				data: null,
				columns: null,
				options: {
					fileName: 'Upsell Export.xlsx',
					fontFamily: 'Arial',
					fontSize: 10,
				},
			},
		}
	},
	
	methods: {
		onTransactionExport( transactions ){
			let headers = {
				// transactionId: 'Transaksjons ID',
				department: 'Avdeling',
				user: 'Bruker',
				date: 'Dato / Tidspunkt',
				transactionType: 'Produkt / Oppgave',
				name: 'Navn',
				value: 'Verdi',
				qty: 'Antall',
				sum: 'Sum',
				comment: 'Kommentar',
			};
			
			let itemsFormatted = [];
			
			transactions.forEach( item => {
				let user = item.username;
				
				try {
					if ( this.users && this.users.find( u => u.username == item.username) ) {
						const userFound = this.users.find( u => u.username == item.username);
						user = `${userFound.given_name} ${userFound.family_name} (${userFound.email})`.replace(/,/g, '');
					}
				}
				catch (e) {
					console.log('user error', e);
				}
				
				
				let department = item.departmentId.replace(/,/g, '');
				
				try {
					if ( this.departments && this.departments.find( d => d.departmentId == item.departmentId) ) {
						department = this.departments.find( d => d.departmentId == item.departmentId).name;
					}
				}
				catch (e) {
					console.log('user error', e);
				}
				
				
				
				let newRow = {
					transactionId: item.transactionId.replace(/,/g, ''),
					department,
					user,
					date: this.$moment( item.createdAt ).format('lll'),
				};
				
				if (item.products) {
					item.products.forEach( product => {
						let productRow = {};
						
						productRow.transactionType = 'Produkt';
						productRow.name = product.name.replace(/,/g, '');
						productRow.qty = Number(product.qty);
						productRow.value = Number(product.value);
						productRow.sum = Number(product.qty) * Number(product.value);
						productRow.comment = product.comment || '';
						
						itemsFormatted.push({
							...newRow,
							...productRow,
						});
					});
				}
				
				if (item.tasks) {
					item.tasks.forEach( task => {
						let taskRow = {};
						
						taskRow.transactionType = 'Oppgave';
						taskRow.name = task.name.replace(/,/g, '');
						taskRow.qty = Number(task.qty);
						taskRow.comment = task.comment || '';
						
						itemsFormatted.push({
							...newRow,
							...taskRow,
						});
					});
				}
			});
			
			let fileTitle = `Upsell - ${this.$moment( this.from ).format('lll')} to ${this.$moment( this.to ).format('lll')}`;
			
			if ( this.exportFormat == 'excel') {
				this.exportExcelFile(headers, itemsFormatted, fileTitle);
			}
			else {
				this.exportCSVFile(headers, itemsFormatted, fileTitle);
			}
		},
		
		convertToCSV(objArray) {
			let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
			let str = '';
			
			for (let i = 0; i < array.length; i++) {
				let line = '';
				for (let index in array[i]) {
					if (line != '') line += ','

					line += array[i][index];
				}

				str += line + '\r\n';
			}

			return str;
		},
		
		exportCSVFile(headers, items, fileTitle) {
			if (headers) {
				items.unshift(headers);
			}
			
			// Convert Object to JSON
			let jsonObject = JSON.stringify(items);
			
			let csv = this.convertToCSV(jsonObject);
			
			let exportedFilenmae = fileTitle + '.csv' || 'export.csv';
			
			let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
			if (navigator.msSaveBlob) { // IE 10+
				navigator.msSaveBlob(blob, exportedFilenmae);
			} 
			else {
				let link = document.createElement("a");
				
				if (link.download !== undefined) { // feature detection
					// Browsers that support HTML5 download attribute
					let url = URL.createObjectURL(blob);
					link.setAttribute("href", url);
					link.setAttribute("download", exportedFilenmae);
					link.style.visibility = 'hidden';
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}
			}
		},
		
		async exportExcelFile(headers, items, fileTitle) {
			console.log(headers, items, fileTitle);
			
			const columnSetup = {
				height: 24,
				alignVertical: 'center',
			};
			
			const columnSetupHeader = {
				fontWeight: 'bold',
				backgroundColor: '#f0f0eb',
				color: '#333333',
				stickyRowsCount: 1,
			};
			
			this.excel.data = [];
			let headerArray = [];
			
			for (const [key, value] of Object.entries( headers ) ) {
				headerArray.push({ // Column
					value,
					...columnSetup,
					...columnSetupHeader,
				});
			}
			
			this.excel.data.push( headerArray );
			
			const columnTransactions = {
				// height: 24,
				// alignVertical: 'bottom',
			};
			
			items.forEach( transaction => {
				let rowArray = [];
				
				for (const [key, value] of Object.entries( headers ) ) {
					rowArray.push({
						// value: transaction[ key ],
						value: transaction[ key ],
						...columnSetup,
						...columnTransactions,
					});
				}
				
				this.excel.data.push( rowArray );
			});
			
			this.excel.columns = [
				// { width: 15 }, // transactionId
				{ width: 30 }, // department
				{ width: 40 }, // user
				{ width: 22 }, // date
				{ width: 10 }, // transactionType
				{ width: 30 }, // name
				{ width: 8 }, // value
				{ width: 8 }, // qty
				{ width: 8 }, // sum
				{ width: 60 }, // comment
			];
			
			// will use file download to blob in browser.
			return await writeXlsxFile( this.excel.data, 
				{
					columns: this.excel.columns,
					...this.excel.options,
					fileName: fileTitle + '.xlsx', //'Upsell Export.xlsx',
				}
			);
		},
		
	},
}
